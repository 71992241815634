

import store from '@/store/store'
const venueId = store.getters.currentVenueId
const venue = store.getters.currentVenue? JSON.parse(store.getters.currentVenue): {};
const currentUser = store.getters.currentUser ? store.getters.currentUser : {};
let newUpdates =  store.state.updatesList? store.state.updatesList.bookingsUpdates.filter(elm=>elm.read_at == null): [];
export default [
  {
    url: '/'+venueId+'/bookings-management',
    name: 'bookings-management',
    slug: 'home',
    icon: 'BookIcon',
    tagColor: 'success',
    i18n: 'pages.bookingsManagement'
  },
  {
    url:  `/${venueId}/calendar`,
    name: 'Calendar',
    slug: 'venue-calendar',
    icon: 'CalendarIcon',
    tagColor: 'success',
    i18n: 'Calendar'
  },
  // {
  //   url: `/${venueId}/pricings-booking-options`,
  //   name: 'PlaceInfo',
  //   slug: 'pricings-booking-options',
  //   icon: 'DollarSignIcon',
  //   i18n: 'pages.PricingsBookingOptions'
  // },
  {
    icon: 'DollarSignIcon',
    i18n: 'pages.PricingsBookingOptions',
    submenu: [
      {
        url: `/${venueId}/booking-options`,
        name: 'BookingOptions',
        slug: 'booking-booking-options',
        i18n: 'pages.BookingOptions',
      },
      {
        url: `/${venueId}/services`,
        name: 'Services',
        slug: 'services',
        i18n: 'pages.Services',
      },
    ]
  },
  {
    url: `/${venueId}/BookingsList`,
    name: 'BookingsList',
    slug: 'bookings-list',
    icon: 'CalendarIcon',
    i18n: 'BookingsList'
  },
  // {
  //   url: `/${venueId}/venue-statistics`,
  //   name: 'venue-statistics',
  //   slug: 'venue-statistics',
  //   icon: 'PieChartIcon',
  //   i18n: 'venueStatistics'
  // },
  {
    url: `/${venueId}/venue-settlements`,
    name: 'venue-settlements',
    slug: 'venue-settlements',
    icon: 'FileIcon',
    i18n: 'pages.venueSettlements',
    // tag: 'new',
    // tagColor: 'success',
    // tagIcon: 'far fa-star'
  },
  {
    url: `/${venueId}/reviews`,
    name: 'reviews',
    slug: 'reviews',
    icon: 'FileIcon',
    i18n: 'pages.reviews',
    // tag: 'new',
    // tagColor: 'success',
    // tagIcon: 'far fa-star'
  },
  {
    url: `/${venueId}/question-answers`,
    name: 'question-answers',
    slug: 'question-answers',
    icon: 'InfoIcon',
    i18n: 'pages.questions',
    tag: 'soon',
    tagColor: 'warning',
    tagIcon: 'far fa-fire'
  },
  {
    url: `/${venueId}/business-marketing`,
    name: 'business-marketing',
    slug: 'business-marketing',
    icon: 'HeartIcon',
    i18n: 'pages.business_marketing',
    tag: 'new',
    tagColor: 'success',
    tagIcon: 'far fa-star'
  },
  {
    url: `/${venueId}/bookings-leads`,
    name: 'Leads',
    slug: 'bookings-leads',
    icon: 'StarIcon',
    i18n: 'leads',
    // tag: 'new',
    // tagColor: 'success',
    // tagIcon: 'far fa-star'
  },
  {
    url: `/${venueId}/discount-coupons`,
    name: 'discountCoupons',
    slug: 'discount-coupons',
    icon: 'TagIcon',
    i18n: 'discountCoupons',
    // tag: 'new',
    // tagColor: 'success',
    // tagIcon: 'far fa-star'
  },
  {
    url: `/${venueId}/business-events`,
    name: 'businessEvents',
    slug: 'business-events',
    icon: 'TagIcon',
    i18n: 'pages.businessEvents',
    // tag: 'new',
    // tagColor: 'success',
    // tagIcon: 'far fa-star'
  },

  // {
  //   tag: newUpdates.length === 0 ? null : newUpdates.length,
  //   url: `/${venueId}/bookingsUpdates`,
  //   name: 'BookingsUpdates',
  //   slug: 'bookings-updates',
  //   icon: 'BellIcon',
  //   tagColor: 'primary',
  //   i18n: 'BookingsUpdates'
  // },
  // {
  //   url: `/${venueId}/price-calculator`,
  //   name: 'PriceCalculator',
  //   slug: 'price-calculator',
  //   icon: 'DollarSignIcon',
  //   i18n: 'priceCalculator'
  // },
  {
    url: `/${venueId}/on-boarding`,
    name: 'OnBoarding',
    slug: 'on-boarding',
    icon: 'ListIcon',
    i18n: 'pages.onBoarding'
  },
  {
    url:`/${venueId}/our-services`,
    name:'OurServices',
    slug:'our-services',
    icon: 'InfoIcon',
    i18n: 'pages.eventfulServices.geoupHeader',
  },
    
  
    // submenu: [
    //   {
    //     url: `https://wa.me/966505457219?text=السلام عليكم، انا ${currentUser.full_name} من ${venue.arabic_name} واحتاج للمساعدة`,
    //     target: '_blank',
    //     slug: 'external',
    //     i18n: 'pages.eventfulServices.contactUs'
    //   },
    //   {
    //     url: 'https://wa.me/966505457219?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20التصوير%20ثلاثي%20الأبعاد',
    //     target: '_blank',
    //     slug: 'external',
    //     i18n: 'pages.eventfulServices.thirdDimensionPhotography'
    //   },
    //   {
    //     url: 'https://wa.me/966505457219?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20إدارة%20حسابات%20السوشل%20ميديا',
    //     target: '_blank',
    //     slug: 'external',
    //     i18n: 'pages.eventfulServices.manageSocialMedia'
    //   },
    //   {
    //     url: 'https://wa.me/966505457219?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20موقع%20الكتروني%20خاص',
    //     target: '_blank',
    //     slug: 'external',
    //     i18n: 'pages.eventfulServices.specialWebsite'
    //   },
    //   {
    //     url: 'https://wa.me/966505457219?text=مرحبا،%20أرغب%20بالاستفسار%20عن%20خدمة%20تطبيق%20خاص',
    //     target: '_blank',
    //     slug: 'external',
    //     i18n: 'pages.eventfulServices.specialApp'
    //   },
    // ]

  

  

]

