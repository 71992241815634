<template>
  <div class="the-navbar__user-meta flex items-center" >

    <div class="text-right leading-tight hidden sm:block">
      <div class="flex">
        <h4 class="mt-1 mx-2"><i class="far fa-sun fa-lg text-warning" v-if="this.semiLightTheme"></i><i class="far fa-moon fa-lg" v-else></i></h4>
        <vs-switch v-model="semiLightTheme" @click="changeTheme"/>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  computed: {
    semiLightTheme: {
      get () { return this.$store.state.theme == 'semi-dark'},
      set (val) { 
        if(typeof(val) != 'boolean') {
          val = val == 'dark' ? 'dark' : 'semi-dark'; 
          this.$store.dispatch('updateTheme', val) 
        }
      }
    },
  },
  methods: {
    changeTheme() {
      let val = this.semiLightTheme ? 'dark' : 'semi-dark';
      this.semiLightTheme = val;
    }
  }
}
</script>
