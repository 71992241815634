var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main",class:[_vm.layoutTypeClass, _vm.navbarClasses, _vm.footerClasses, {'no-scroll': _vm.isAppPage}]},[(_vm.notification)?_c('vs-popup',{staticClass:"holamundo small-popup-25",attrs:{"title":"","active":_vm.newFeatureNotificationActive},on:{"update:active":function($event){_vm.newFeatureNotificationActive=$event},"close":_vm.closePopup}},[_c('div',{staticClass:"container w-full"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full md:mb-2 pl-2 mt-4"},[(_vm.notification.data.image)?_c('img',{staticClass:"responsive",attrs:{"src":`${_vm.baseURL}/${_vm.notification.data.image}`}}):_vm._e()]),_c('div',{staticClass:"vx-col w-full md:mb-2 pl-2 mt-4"},[_c('h2',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.notification.data.title))]),_c('p',[_vm._v(_vm._s(_vm.notification.data.details))])])])])]):_vm._e(),(!_vm.disableThemeTour && (_vm.windowWidth >= 1200 && _vm.mainLayoutType === 'vertical' && _vm.verticalNavMenuWidth == 'default'))?_c('vx-tour',{attrs:{"steps":_vm.steps}}):_vm._e(),(!_vm.isProduction)?_c('the-customizer',{attrs:{"footerType":_vm.footerType,"hideScrollToTop":_vm.hideScrollToTop,"navbarType":_vm.navbarType,"navbarColor":_vm.navbarColor,"routerTransition":_vm.routerTransition},on:{"toggleHideScrollToTop":_vm.toggleHideScrollToTop,"updateFooter":_vm.updateFooter,"updateNavbar":_vm.updateNavbar,"updateNavbarColor":_vm.updateNavbarColor,"updateRouterTransition":_vm.updateRouterTransition}}):_vm._e(),_c('v-nav-menu',{attrs:{"navMenuItems":_vm.navMenuItems,"title":_vm.$t('appName'),"parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, {'show-overlay': _vm.bodyOverlay}],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),(_vm.mainLayoutType === 'horizontal' && _vm.windowWidth >= 1200)?[_c('the-navbar-horizontal',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navbarType":_vm.navbarType}}),(_vm.navbarType === 'static')?_c('div',{staticStyle:{"height":"62px"}}):_vm._e(),_c('h-nav-menu',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navMenuItems":_vm.navMenuItems}})]:[_c('the-navbar-vertical',{class:[
        {'text-white' : _vm.isNavbarDark  && !_vm.isThemeDark},
        {'text-base'  : !_vm.isNavbarDark && _vm.isThemeDark}
      ],attrs:{"navbarColor":_vm.navbarColor}})],_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content"},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap items-center mb-6"},[(_vm.$route.meta.breadcrumb)?_c('vx-breadcrumb',{staticClass:"ml-4 md:block hidden",attrs:{"route":_vm.$route,"isRTL":_vm.$vs.rtl}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"content-area__content"},[(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","right":_vm.$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px',"visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg btn-back-to-top",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e(),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"changeRouteTitle":_vm.changeRouteTitle,"setAppClasses":(classesStr) => _vm.$emit('setAppClasses', classesStr)}})],1)],1)],1)])]),_c('the-footer')],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }